import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ProductContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '50px',
  marginTop: ' 20px',
  [theme.breakpoints.down('md')]: {
    gap: '0px',
    flexDirection: 'column'
  }
}))

export const Image = styled('img')`
  width: 100%;
  height: fit-content;
  border-radius: 20px;
  cursor: pointer;
`

export const ImageContainer = styled('div')(({ theme }) => ({
  width: '45%',
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))

export const InfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '45%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    width: '100%',
    textAlign: 'center'
  }
}))

export const SecondImageContainer = styled('div')`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`

export const SecondImage = styled('img')`
  width: 10vw;
  height: 10vw;
  border-radius: 20px;
  cursor: pointer;
  object-fit: cover;
  &:hover {
    border: 1px solid white;
  }
`

export const ImagesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center'
  }
}))

export const AddToShoppingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '30px',
  paddingTop: '20px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}))

export const Title = styled(Typography)`
  text-decoration: none;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`

export const ProductImage = styled('img')`
  border-radius: 5px;
  height: 80px;
`

export const OrderPrice = styled(Typography)`
  font-size: 16px;
`

export const TotalPrice = styled('div')`
  font-size: 20px;
  font-weight: 500;
`

export const AddressesContainer = styled('div')`
  margin-top: 20px;
  display: flex;
  gap: 30px;
`

export const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center'
  }
}))

interface PriceProps {
  hasPromotion: boolean
  isSold: boolean
}

export const Price = styled(Typography)<PriceProps>`
  text-decoration: ${({ hasPromotion, isSold }) =>
    hasPromotion || isSold ? 'line-through' : 'none'};
`

interface PromotionProps {
  isSold: boolean
}

export const Promotion = styled(Typography)<PromotionProps>`
  text-decoration: ${({ isSold }) => (isSold ? 'line-through' : 'none')};
  font-weight: bold;
  font-size: 18px;
`

export const Sold = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
`

export const Container = styled('div')`
  margin: 15px 0;
`

export const CommentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
`

export const DeliveryContainer = styled('div')`
  display: flex;
  gap: 30px;
`

export const NotDeliverable = styled(Typography)`
  margin-top: 10px;
  font-weight: bold;
`
