import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material'
import { KeyboardArrowLeft, ShoppingCartOutlined } from '@mui/icons-material'
import { navigate } from 'gatsby'
import { buildImageLink } from '../../helpers'
import { routePaths } from '../../constants/routes'
import { UploadFile } from '../../helpers/types'

type Props = {
  isOpen: boolean
  handleClose: () => void
  title: string
  image: UploadFile
  quantity: number
}

const ProductDialog = ({
  isOpen,
  handleClose,
  title,
  image,
  quantity
}: Props) => (
  <Dialog
    onClose={handleClose}
    aria-labelledby="simple-dialog-title"
    open={isOpen}
    maxWidth="sm"
    fullWidth
  >
    <DialogTitle id="simple-dialog-title">
      Produit ajouté au panier !
    </DialogTitle>
    <DialogContent
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <img
        src={buildImageLink(image.url)}
        alt={image.alternativeText}
        style={{ maxWidth: '300px', borderRadius: '20px' }}
      />
      <DialogContentText id="alert-dialog-slide-description" mt={2}>
        {`${quantity} x ${title}`}
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ justifyContent: 'space-evenly' }}>
      <Button
        onClick={handleClose}
        color="secondary"
        variant="outlined"
        startIcon={<KeyboardArrowLeft />}
      >
        Continuer mes achats
      </Button>
      <Button
        onClick={() => navigate(routePaths.shoppingCart)}
        color="primary"
        variant="contained"
        startIcon={<ShoppingCartOutlined />}
      >
        Voir le panier
      </Button>
    </DialogActions>
  </Dialog>
)

export default ProductDialog
