import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import gfm from 'remark-gfm'
import { AddShoppingCart, Feed, Home, Store } from '@mui/icons-material'
import { Button, TextField, Typography } from '@mui/material'
import { uniq } from 'lodash'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { graphql, useStaticQuery } from 'gatsby'

import { buildImageLink, formatPrice, getTotalPrice } from '../../helpers'
import {
  Image,
  SecondImage,
  SecondImageContainer,
  ImagesContainer,
  ImageContainer,
  ProductContainer,
  InfoContainer,
  Price,
  Promotion,
  Sold,
  TextContainer,
  AddToShoppingContainer,
  NotDeliverable
} from './Styled'
import { useProfileContext } from '../../contexts/profileContext'
import SEO from '../../components/common/seo'
import { routePaths } from '../../constants/routes'
import Header from '../../components/common/Header'
import {
  LocalStorageKey,
  getLSValue,
  setLSValue
} from '../../helpers/localStorage'
import ProductDialog from '../../components/ProductDialog'
import { SubContainer } from '../../components/common/Styled'
import { fetchProduct } from '../../helpers/api/products'

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
    shop: strapiShop {
      referencing {
        metaTitle
      }
    }
  }
`

const ProductPage = props => {
  const { pageContext } = props
  const id = pageContext?.strapi_id
  const profile = useProfileContext()
  const user = profile?.user
  const order = profile?.order
  const createOrder = profile?.createOrder
  const updateOrder = profile?.updateOrder
  const updateLocaleOrder = profile?.updateLocaleOrder

  const { home, shop } = useStaticQuery(query)

  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const [selectedImage, setSelectedImage] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  if (!pageContext) return null

  const {
    title,
    description: {
      data: { description }
    },
    images,
    quantity: productQuantity,
    price,
    promotion,
    sold,
    deliverable
  } = pageContext

  const [actualQuantity, setActualQuantity] = useState(productQuantity)
  const [actualSold, setActualSold] = useState(sold)

  useEffect(() => {
    const getQuantity = async () => {
      const result = await fetchProduct(id)
      setActualQuantity(result.quantity)
      setActualSold(result.sold)
    }
    getQuantity()
  }, [id])

  const handleAddToOrder = async () => {
    setIsOpen(true)
    if (user) {
      const formattedQuantity = quantity

      const productQuantities = order?.productQuantities || {}
      productQuantities[id as string] =
        formattedQuantity + (productQuantities[id as string] || 0)

      if (order) {
        await updateOrder({
          products: uniq([...order?.products.map(product => product.id), id]),
          productQuantities
        })
      } else {
        await createOrder({
          products: [id],
          productQuantities,
          user: user.id
        })
      }
    } else {
      const storedOrder = getLSValue(LocalStorageKey.ORDER)
      const productQuantity = storedOrder?.productQuantities[id.toString()] || 0

      if (order) {
        const data = {
          products: uniq([...storedOrder?.products, id]),
          productQuantities: {
            ...storedOrder.productQuantities,
            [id as string]: productQuantity + quantity
          }
        }

        setLSValue(LocalStorageKey.ORDER, data)
        const products = [
          ...order?.products,
          {
            ...pageContext,
            id,
            images
          }
        ]
        const productQuantities = {
          ...order.productQuantities,
          [id as string]: productQuantity + quantity
        }
        updateLocaleOrder({
          products,
          productQuantities,
          totalPrice: getTotalPrice(products, productQuantities)
        })
      } else {
        const data = {
          products: [id],
          productQuantities: {
            [id as string]: quantity
          }
        }
        setLSValue(LocalStorageKey.ORDER, data)
        const products = [
          {
            ...pageContext,
            id,
            images
          }
        ]
        const productQuantities = {
          [id as string]: productQuantity + quantity
        }
        updateLocaleOrder({
          products,
          productQuantities,
          totalPrice: getTotalPrice(products, productQuantities)
        })
      }
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setQuantity(0)
  }

  const closeLightbox = () => {
    setViewerIsOpen(false)
    setCurrentImage(0)
  }

  const openLightbox = () => {
    setCurrentImage(selectedImage)
    setViewerIsOpen(true)
  }

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <Store />,
      label: shop.referencing.metaTitle,
      route: routePaths.shop
    },
    {
      icon: <Feed />,
      label: title
    }
  ]

  const orderQuantity =
    order?.productQuantities?.[parseInt(id, 10)] ||
    getLSValue(LocalStorageKey.ORDER)?.productQuantities?.[parseInt(id, 10)] ||
    0

  const finalQuantity = actualQuantity - orderQuantity

  const photos = images.map(({ url, width, height }) => ({
    src: buildImageLink(url),
    width,
    height
  }))

  return (
    <SubContainer>
      <SEO title={title} description={description} image={images[0].url} />
      <Header {...{ items, title }} />
      <ProductContainer>
        <ImageContainer>
          <Image
            onClick={openLightbox}
            src={buildImageLink(images[selectedImage].url)}
            alt={images[selectedImage].alternativeText}
          />
        </ImageContainer>

        <InfoContainer>
          <div>
            <Typography variant="body1" component="div">
              <Markdown remarkPlugins={[gfm]}>{description}</Markdown>
            </Typography>
            {!actualSold && (
              <Typography variant="body1" style={{ margin: '3em 0 1em' }}>
                Quantité disponible : {finalQuantity}
              </Typography>
            )}
            <TextContainer>
              <Typography variant="body1" style={{ margin: '1em 0' }}>
                Prix :
              </Typography>
              <Price
                align="center"
                variant="body1"
                hasPromotion={!!promotion}
                isSold={actualSold}
              >
                {formatPrice(price)}
              </Price>
              {promotion && (
                <Promotion
                  align="center"
                  variant="body1"
                  color="primary"
                  isSold={actualSold}
                >
                  {formatPrice(promotion)}
                </Promotion>
              )}
              {actualSold && (
                <Sold color="primary" align="center" variant="body1">
                  Vendu !
                </Sold>
              )}
            </TextContainer>
            {process.env.GATSBY_DISPLAY_SHOPPING_OPTIONS && !actualSold && (
              <AddToShoppingContainer>
                <TextField
                  sx={{
                    minWidth: 100,
                    borderColor: 'white',
                    height: 'fit-content'
                  }}
                  id="quantity-text-field"
                  label="Quantité"
                  type="number"
                  inputProps={{
                    min: '0',
                    max: finalQuantity
                  }}
                  variant="outlined"
                  color="secondary"
                  value={quantity}
                  onChange={e => setQuantity(parseInt(e.target.value, 10))}
                />
                <Button
                  startIcon={<AddShoppingCart />}
                  color="primary"
                  variant="contained"
                  onClick={handleAddToOrder}
                  disabled={quantity <= 0 || quantity > productQuantity}
                >
                  Ajouter au panier
                </Button>
              </AddToShoppingContainer>
            )}
            {!actualSold && !deliverable && (
              <NotDeliverable color="primary" variant="body1">
                Produit non disponible à la livraison à domicile.
              </NotDeliverable>
            )}
          </div>
          <ImagesContainer>
            <SecondImageContainer>
              {images
                .filter(
                  image => image.strapi_id !== images[selectedImage].strapi_id
                )
                .map(image => (
                  <SecondImage
                    key={image.strapi_id}
                    onClick={() =>
                      setSelectedImage(
                        images.findIndex(
                          img => img.strapi_id === image.strapi_id
                        )
                      )
                    }
                    src={buildImageLink(image.url)}
                    alt={image.alternativeText}
                  />
                ))}
            </SecondImageContainer>
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={photos.map(x => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </ImagesContainer>
        </InfoContainer>
      </ProductContainer>
      <ProductDialog
        image={images[0]}
        {...{ isOpen, handleClose, quantity, title }}
      />
    </SubContainer>
  )
}

export default ProductPage
